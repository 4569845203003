// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-document-page-tsx": () => import("./../../../src/templates/DocumentPage.tsx" /* webpackChunkName: "component---src-templates-document-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/IndexPage.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-not-found-page-tsx": () => import("./../../../src/templates/NotFoundPage.tsx" /* webpackChunkName: "component---src-templates-not-found-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/ProjectPage.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */),
  "component---src-templates-projects-page-tsx": () => import("./../../../src/templates/ProjectsPage.tsx" /* webpackChunkName: "component---src-templates-projects-page-tsx" */)
}

